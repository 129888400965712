define("discourse/plugins/discourse-bbb/discourse-bbb/controllers/insert-bbb", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _controller, _modalFunctionality, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("meetingID"), (_obj = {
    keyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    onShow() {
      this.setProperties({
        meetingID: "",
        attendeePW: "",
        moderatorPW: "",
        buttonText: "",
        mobileIframe: false,
        desktopIframe: true
      });
    },
    randomID() {
      return Math.random().toString(36).slice(-8);
    },
    insertDisabled(meetingID) {
      return (0, _utils.isEmpty)(meetingID);
    },
    actions: {
      insert() {
        const btnTxt = this.buttonText ? ` label="${this.buttonText}"` : "";
        this.toolbarEvent.addText(`[wrap=discourse-bbb meetingID="${this.meetingID}"${btnTxt} attendeePW="${this.randomID()}" moderatorPW="${this.randomID()}" mobileIframe="${this.mobileIframe}" desktopIframe="${this.desktopIframe}"][/wrap]`);
        this.send("closeModal");
      },
      cancel() {
        this.send("closeModal");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "insertDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "insertDisabled"), _obj)), _obj)));
});